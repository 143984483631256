<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageGroupRewardForm">
        <v-text-field
          solo
          v-model="reward.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
        ></v-text-field>
        <v-text-field
          solo
          :min="rubyEnum.rewards.min"
          :max="rubyEnum.rewards.max"
          :rules="[rules.required, rules.isInteger, rules.minMaxRuby]"
          v-model="reward.ruby"
          :label="$t('common.ruby')"
          :hint="
            $t('common.minMaxValue', {
              min: rubyEnum.rewards.min,
              max: rubyEnum.rewards.max
            })
          "
        ></v-text-field>
        <v-switch
          class="mt-0 mb-4"
          v-model="reward.requireApprove"
          :label="$t('common.requireApprove')"
          color="app-blue"
          hide-details
        ></v-switch>
        <h3>{{ $t('rewards.usabilityTitle') }}</h3>
        <v-radio-group v-model="reward.type" row @change="selectType">
          <v-radio
            :label="$t('rewards.constant')"
            :value="rewardTypes.constant"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('rewards.once')"
            :value="rewardTypes.once"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('rewards.times')"
            :value="rewardTypes.times"
            color="app-blue"
            class="mb-2"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="toggleTimesField"
          solo
          v-model.number="reward.timesToUse"
          :rules="[...rules.timesValidator()]"
          :min="minTimesToUse"
          :max="maxTimesToUse"
          :label="$t('rewards.howManyTimes')"
          :hint="
            $t('common.minMaxValue', {
              min: this.minTimesToUse,
              max: this.maxTimesToUse
            })
          "
        ></v-text-field>
        <app-multi-select
          v-model="reward.assignedUsers"
          :items="members"
          :label="$t('common.assignTo')"
          :rules="[rules.required]"
          item-value="userId"
          item-text="name"
          class="tags-select"
        ></app-multi-select>
      </v-form>
    </v-container>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppMultiSelect from '../../shared/AppMultiSelect';
import validators from '../../../common/validators';
import {
  ADD_GROUP_REWARD,
  UPDATE_GROUP_REWARD,
  COPY_GROUP_REWARD
} from '../../../store/rewards/types';
import rewardTypes from '../../../enums/reward.types';
import rubyEnum from '../../../enums/ruby';
import manageModalsType from '../../../enums/manage.modals.types';

const MIN_TIMES_TO_USE = 1;
const MAX_TIMES_TO_USE = 100;

export default {
  name: 'manage-group-reward-modal',
  components: {
    AppMultiSelect
  },
  props: {
    selectedReward: Object,
    members: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      require: true
    },
    modalType: {
      type: String,
      default: manageModalsType.create
    }
  },
  data() {
    return {
      reward: {
        name: '',
        ruby: '',
        type: rewardTypes.constant,
        requireApprove: true,
        timesToUse: '',
        assignedUsers: []
      },
      rewardTypes,
      rubyEnum,
      minTimesToUse: MIN_TIMES_TO_USE,
      maxTimesToUse: MAX_TIMES_TO_USE,
      toggleTimesField: false,
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.rubyInteger')),
        minMaxRuby: validators.minMaxValue(
          rubyEnum.rewards.min,
          rubyEnum.rewards.max,
          this.$i18n.t('common.minMaxValue', {
            min: rubyEnum.rewards.min,
            max: rubyEnum.rewards.max
          })
        ),
        timesValidator: () => {
          if (this.toggleTimesField) {
            return [
              validators.required(this.$i18n.t('common.requiredField')),
              validators.minMaxValue(
                MIN_TIMES_TO_USE,
                MAX_TIMES_TO_USE,
                this.$i18n.t('common.minMaxValue', {
                  min: MIN_TIMES_TO_USE,
                  max: MAX_TIMES_TO_USE
                })
              )
            ];
          }

          return true;
        }
      }
    };
  },
  created() {
    if (this.selectedReward) {
      this.reward = {
        ...this.selectedReward,
        assignedUsers: this.selectedReward.assignedUsers.map(m => m.userId)
      };
    }

    if (this.reward.type === rewardTypes.times) {
      this.toggleTimesField = true;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageGroupRewardForm.validate()) {
        return;
      }

      const { id } = this.$route.params;
      const data = {
        groupId: id,
        reward: this.reward,
        members: this.members
      };

      if (this.modalType === manageModalsType.create) {
        await this.$store.dispatch(ADD_GROUP_REWARD, data);
      } else if (this.modalType === manageModalsType.edit) {
        await this.$store.dispatch(UPDATE_GROUP_REWARD, data);
      } else {
        await this.$store.dispatch(COPY_GROUP_REWARD, data);
      }

      this.$emit('close');
    },
    selectType() {
      const { type } = this.reward;

      if (type === rewardTypes.times) {
        this.toggleTimesField = true;
        this.reward.timesToUse = '';
      } else {
        this.toggleTimesField = false;
      }
    }
  }
};
</script>
