<template>
  <v-select
    v-model="selectedItems"
    solo
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :label="label"
    :rules="rules"
    multiple
    chips
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'app-blue' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            :class="selectedItems.length > 0 ? 'app-blue--text' : ''"
          >
            {{ $t('common.selectAll') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template #selection="{ item }">
      <v-chip color="app-blue">{{ item[itemText] }}</v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'app-multi-select',
  props: {
    value: Array,
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: String,
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(items) {
        if (items.every(item => typeof item === 'string')) {
          this.$emit('input', items);
        } else {
          this.$emit(
            'input',
            items.map(item => item[this.itemValue])
          );
        }
      }
    },
    allItemsSelected() {
      return this.selectedItems.length === this.items.length;
    },
    someItemsSelected() {
      return this.selectedItems.length > 0 && !this.allItemsSelected;
    },
    icon() {
      if (this.allItemsSelected) return 'mdi-close-box';
      if (this.someItemsSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allItemsSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.slice();
        }
      });
    }
  }
};
</script>
