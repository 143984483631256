<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t('groups.assignedUsers') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4">
      <v-card class="mb-6 border-top-green">
        <h3
          class="two-lines font-weight-regular py-2 text-center app-green--text"
        >
          {{ itemName }}
        </h3>
      </v-card>
      <v-card
        v-for="assignedUser of assignedUsers"
        :key="assignedUser.id"
        class="my-2 mb-5"
      >
        <v-row align="center" justify="space-around">
          <v-col cols="4" class="text-center">
            <user-avatar
              :size="60"
              :user="assignedUser"
              textSizeClass="title"
            ></user-avatar>
          </v-col>
          <v-col cols="8">
            {{ assignedUser.name }}
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import UserAvatar from '../shared/UserAvatar';

export default {
  name: 'assigned-users-modal',
  components: {
    UserAvatar
  },
  props: {
    itemName: {
      type: String,
      required: true
    },
    assignedUsers: {
      type: Array,
      required: true
    }
  }
};
</script>
