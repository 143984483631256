<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageGroupTaskForm">
        <v-text-field
          solo
          v-model="task.title"
          :rules="[rules.required]"
          :label="$t('common.title')"
          :disabled="task.completed"
        ></v-text-field>
        <v-textarea
          solo
          v-model="task.notes"
          :label="$t('common.notes')"
          :disabled="task.completed"
        ></v-textarea>
        <v-text-field
          solo
          :min="rubyEnum.min"
          :max="rubyEnum.max"
          :rules="[rules.required, rules.isInteger, rules.minMaxRuby]"
          v-model="task.ruby"
          :label="$t('common.ruby')"
          :hint="
            $t('common.minMaxValue', {
              min: rubyEnum.min,
              max: rubyEnum.max
            })
          "
          :disabled="task.completed"
        ></v-text-field>
        <v-select
          v-model="task.tags"
          :items="mappedTags"
          :rules="[rules.maxArrayLength]"
          chips
          :label="$t('tags.tags')"
          item-value="id"
          item-text="name"
          multiple
          solo
          class="tags-select"
          :disabled="task.completed"
        >
          <template #selection="{ item }">
            <v-chip color="app-blue">{{ item.name }}</v-chip>
          </template>
        </v-select>
        <v-switch
          class="mt-0 mb-4"
          v-model="task.requireApprove"
          :label="$t('common.requireApprove')"
          color="app-blue"
          hide-details
        ></v-switch>
        <v-switch
          class="mt-0 mb-4"
          v-model="task.requireResult"
          :label="$t('tasks.requireResultWhenComplete')"
          color="app-blue"
          hide-details
        ></v-switch>
        <h3 class="mt-5">{{ $t('tasks.type') }}</h3>
        <v-radio-group v-model="task.type" row :disabled="task.completed">
          <v-radio
            :label="$t('tasks.todo')"
            :value="taskTypes.todo"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('tasks.daily')"
            :value="taskTypes.daily"
            color="app-blue"
            class="mb-2"
          ></v-radio>
          <v-radio
            :label="$t('tasks.weekly')"
            :value="taskTypes.weekly"
            color="app-blue"
            class="mb-2"
          ></v-radio>
        </v-radio-group>
        <app-multi-select
          v-model="task.assignedUsers"
          :items="members"
          :label="$t('common.assignTo')"
          :rules="[rules.required]"
          item-value="userId"
          item-text="name"
          class="tags-select"
          :disabled="task.completed"
        ></app-multi-select>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AppMultiSelect from '../../shared/AppMultiSelect';
import validators from '@/common/validators';
import {
  ADD_GROUP_TASK,
  UPDATE_GROUP_TASK,
  COPY_GROUP_TASK,
  CHANGE_TASKS_TYPES_FILTER
} from '../../../store/tasks/types';
import taskTypes from '@/enums/task.types';
import rubyEnum from '../../../enums/ruby';
import manageModalsType from '../../../enums/manage.modals.types';

const MIN_TAGS = 0;
const MAX_TAGS = 3;

export default {
  name: 'manage-group-task-modal',
  components: {
    AppMultiSelect
  },
  props: {
    selectedTask: Object,
    selectedType: {
      type: String,
      default: taskTypes.todo
    },
    shouldUpateFilter: {
      type: Boolean,
      default: true
    },
    members: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      require: true
    },
    modalType: {
      type: String,
      default: manageModalsType.create
    }
  },
  computed: {
    ...mapGetters(['tags']),
    mappedTags() {
      return this.tags.map(tag => ({
        id: tag.id,
        name: this.$i18n.t(`tags.${tag.name}`)
      }));
    }
  },
  data() {
    return {
      taskTypes,
      rubyEnum,
      task: {
        title: '',
        notes: '',
        ruby: '',
        complete: false,
        requireApprove: true,
        requireResult: false,
        type: this.selectedType,
        tags: [],
        assignedUsers: []
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.rubyInteger')),
        minMaxRuby: validators.minMaxValue(
          rubyEnum.min,
          rubyEnum.max,
          this.$i18n.t('common.minMaxValue', {
            min: rubyEnum.min,
            max: rubyEnum.max
          })
        ),
        maxArrayLength: validators.minMaxArrayLength(
          MIN_TAGS,
          MAX_TAGS,
          this.$i18n.t('tasks.maxTagsLength', { number: MAX_TAGS })
        )
      }
    };
  },
  created() {
    this.populateTask();
  },
  methods: {
    populateTask() {
      if (this.selectedTask) {
        this.task = {
          ...this.selectedTask,
          tags: this.selectedTask.tags.map(tag => tag.id),
          assignedUsers: this.selectedTask.assignedUsers.map(m => m.userId)
        };
      }
    },
    async save() {
      if (!this.$refs.manageGroupTaskForm.validate()) {
        return;
      }

      const { id } = this.$route.params;
      const { userId } = this.$route.query;

      const data = {
        groupId: id,
        userId,
        task: this.task,
        members: this.members
      };

      if (this.modalType === manageModalsType.create) {
        await this.$store.dispatch(ADD_GROUP_TASK, data);
      } else if (this.modalType === manageModalsType.edit) {
        await this.$store.dispatch(UPDATE_GROUP_TASK, data);
      } else {
        await this.$store.dispatch(COPY_GROUP_TASK, data);
      }

      if (this.shouldUpateFilter) {
        this.$store.dispatch(CHANGE_TASKS_TYPES_FILTER, {
          groupId: id,
          userId,
          taskType: this.task.type
        });
      }

      this.$emit('close', this.task);
    }
  }
};
</script>

<style lang="scss">
@import '../../../assets/styles/colors';

.tags-select {
  .v-chip {
    color: $white;
    background: $app-blue;
  }
}
</style>
