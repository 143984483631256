<template>
  <div>
    <div
      class="d-inline-block mr-1"
      v-for="(user, index) of assignedUsers"
      :key="user.id"
    >
      <div v-if="index < 3">
        <user-avatar :user="user"></user-avatar>
      </div>
      <div v-else>
        <v-btn fab x-small dark color="app-blue" depressed>
          <span class="caption">+{{ assignedUsers.length - 3 }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '../shared/UserAvatar.vue';

export default {
  name: 'assigned-users',
  components: {
    UserAvatar
  },
  props: {
    assignedUsers: {
      type: Array
    }
  }
};
</script>
