<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageGroupMissionForm">
        <v-text-field
          solo
          v-model="mission.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
          :disabled="!canEditMission"
        ></v-text-field>
        <v-textarea
          solo
          v-model="mission.notes"
          :label="$t('common.notes')"
          :disabled="!canEditMission"
        ></v-textarea>
        <v-row justify="space-between">
          <v-col md="6" sm="12" cols="12" class="pb-md-2 pb-0">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-text-field
                  solo
                  v-model="mission.from"
                  :label="$t('common.from')"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  :value="mission.from"
                  :error-messages="isFromDateValid"
                  :disabled="disableDates"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mission.from"
                no-title
                :min="minDate"
                :disabled="disableDates"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" sm="12" cols="12" class="py-md-3 py-0">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-text-field
                  solo
                  v-model="mission.to"
                  :label="$t('common.to')"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  :value="mission.to"
                  :rules="[rules.required]"
                  :error-messages="isToDateValid"
                  :disabled="disableDates"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mission.to"
                no-title
                :min="minDate"
                :disabled="disableDates"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-switch
          class="mt-0 mb-4"
          v-model="mission.requireApprove"
          :label="$t('common.requireApprove')"
          color="app-blue"
          hide-details
          :disabled="!canEditMission"
        ></v-switch>
        <app-multi-select
          v-model="mission.assignedUsers"
          :items="groupMembers"
          :label="$t('common.assignTo')"
          :rules="[rules.required]"
          item-value="userId"
          item-text="name"
          class="tags-select"
        ></app-multi-select>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AppMultiSelect from '../../shared/AppMultiSelect';
import validators from '../../../common/validators';
import dateTimeManager from '../../../common/date.time.manager';
import missionStatuses from '../../../enums/mission.statuses';
import {
  ADD_GROUP_MISSION,
  UPDATE_GROUP_MISSION,
  COPY_GROUP_MISSION
} from '../../../store/missions/types';
import manageModalsType from '../../../enums/manage.modals.types';

export default {
  name: 'create-edit-group-mission-modal',
  components: {
    AppMultiSelect
  },
  props: {
    selectedMission: Object,
    copy: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      require: true
    },
    modalType: {
      type: String,
      default: manageModalsType.create
    }
  },
  computed: {
    ...mapGetters(['groupMembers']),
    canEditMission() {
      return (
        this.mission.status === missionStatuses.active ||
        this.mission.status === missionStatuses.pending ||
        this.mission.status === missionStatuses.archived
      );
    },
    disableDates() {
      return (
        this.mission.id &&
        this.mission.status !== missionStatuses.pending &&
        this.mission.status !== missionStatuses.archived
      );
    },
    isFromDateValid() {
      if (this.mission.id && this.mission.status !== missionStatuses.pending) {
        return '';
      }

      const currentDate = dateTimeManager.toICODateString(new Date());

      if (dateTimeManager.isBefore(this.mission.from, currentDate)) {
        return this.$i18n.t('missions.fromDateBeforeCurrentDate');
      } else if (
        this.mission.to &&
        dateTimeManager.isAfter(this.mission.from, this.mission.to)
      ) {
        return this.$i18n.t('missions.fromDateAfterToDate');
      }

      return '';
    },
    isToDateValid() {
      if (this.mission.id && this.mission.status !== missionStatuses.pending) {
        return '';
      }

      if (this.mission.to) {
        return dateTimeManager.isBefore(this.mission.from, this.mission.to)
          ? ''
          : this.$i18n.t('missions.toDateBeforeFromDate');
      }

      return '';
    },
    rules() {
      return {
        required: validators.required(this.$i18n.t('common.requiredField'))
      };
    }
  },
  data() {
    return {
      minDate: dateTimeManager.toICODateString(new Date()),
      missionStatuses,
      mission: {
        name: '',
        notes: '',
        from: dateTimeManager.toICODateString(new Date()),
        to: '',
        requireApprove: true,
        status: missionStatuses.pending,
        assignedUsers: []
      }
    };
  },
  created() {
    if (this.selectedMission) {
      if (this.modalType === manageModalsType.edit) {
        this.mission = {
          ...this.selectedMission,
          from: dateTimeManager.toICODateString(this.selectedMission.from),
          to: dateTimeManager.toICODateString(this.selectedMission.to),
          assignedUsers: this.selectedMission.assignedUsers.map(m => m.userId)
        };
      } else {
        this.mission = {
          ...this.selectedMission,
          from: dateTimeManager.toICODateString(new Date()),
          to: '',
          assignedUsers: this.selectedMission.assignedUsers.map(m => m.userId)
        };
      }
    }
  },
  methods: {
    async save() {
      if (
        !this.$refs.manageGroupMissionForm.validate() ||
        this.isFromDateValid ||
        this.isToDateValid
      ) {
        return;
      }

      const { id } = this.$route.params;
      const data = {
        groupId: id,
        mission: this.mission,
        members: this.groupMembers,
        setAsCurrent: true
      };

      if (this.modalType === manageModalsType.create) {
        await this.$store.dispatch(ADD_GROUP_MISSION, data);
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.missionAdded')
        });
      } else if (this.modalType === manageModalsType.edit) {
        await this.$store.dispatch(UPDATE_GROUP_MISSION, data);
      } else {
        await this.$store.dispatch(COPY_GROUP_MISSION, data);
      }

      this.$emit('close');

      // this.$emit('close', this.mission);
    }
  }
};
</script>
